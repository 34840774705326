<template>
	<div>
		<div class="container header-margin">
			<div class="row">
				<div
					class="col-12"
					:class="[block.is_hide_right_column ? '' : 'col-lg-8']"
				>
					<div
						v-if="block.items"
						v-swiper:Slider="swiperOption"
						class="panel-slider slider mb-lg-3"
						@mouseenter="stopSwipe($event)"
						@mouseleave="startSwipe($event)"
					>
						<div class="swiper-wrapper">
							<template
								v-for="(item, index) in block.items"
							>
								<intersect :key="index" @enter.once="onVisible(item)">
									<nuxt-link
										:to="$url.media(item.slug, item.taxonomy_type_slug, {}, item.lang)"
										class="swiper-slide"
										:style="bgStyle(item)"
									>
										<div class="advice-item">
											<div
												v-if="item.date && item.switch_type_to_section"
												class="date"
											>
												{{ item.date | moment('DD MMMM YYYY') }}<span
													v-if="$i18n.locale ==='ru'"
												>. {{ item.section }}</span>
											</div>
											<div
												v-else-if="item.date"
												class="date"
											>
												{{ item.date | moment('DD MMMM YYYY') }}<span
													v-if="$i18n.locale ==='ru'"
												>. {{ item.taxonomy_type }}</span>
											</div>
											<div class="title">
												{{ item.title }}
											</div>
										</div>
									</nuxt-link>
								</intersect>
							</template>
						</div>
						<div class="controls">
							<div class="swiper-button-prev" />
							<div class="swiper-pagination" />
							<div class="swiper-button-next" />
						</div>
					</div>
				</div>
				<div
					v-if="!block.is_hide_right_column"
					class="col-12 col-lg-4"
				>
					<div class="row slider-side">
						<div
							v-if="block.top_card"
							class="col-12 col-md-6 col-lg-12"
						>
							<div
								class="slider-side__item h-100"
								:class="[block.top_card.image && block.top_card.image.src ? 'theme-image' : 'theme-inverse']"
								:style="block.top_card.image && block.top_card.image.src ? `background-image: url('${block.top_card.image.src}')` : ''"
							>
								<div class="item-body">
									<div class="title">
										{{ block.top_card.title }}
									</div>
									<a
										v-if="block.top_card.link"
										:href="block.top_card.link"
										class="btn btn-primary"
									>
										{{ block.top_card.button_text }}
									</a>
								</div>
							</div>
						</div>
						<div
							v-if="block.down_card"
							class="col-12 col-md-6 col-lg-12"
						>
							<div
								class="slider-side__item h-100"
								:class="[block.down_card.image && block.down_card.image.src ? 'theme-image' : 'theme-inverse']"
								:style="block.down_card.image && block.down_card.image.src ? `background-image: url('${block.down_card.image.src}')` : ''"
							>
								<div class="item-body">
									<div class="title">
										{{ block.down_card.title }}
									</div>
									<a
										v-if="block.down_card.link"
										:href="block.down_card.link"
										class="btn btn-primary"
									>
										{{ block.down_card.button_text }}
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Intersect from 'vue-intersect';

import { directive, Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

import '@/utils/moment';
import panel from '~/mixins/panel';

export default {
	name: 'PanelMediaSlider',
	components: {
		// eslint-disable-next-line vue/no-unused-components
		Swiper,
		// eslint-disable-next-line vue/no-unused-components
		SwiperSlide,
		Intersect,
	},
	directives: {
		swiper: directive,
	},
	mixins: [panel],
	data() {
		return {
			swiperOption: {
				spaceBetween: 30,
				centeredSlides: true,
				loop: true,
				autoplay: {
					delay: 4500,
					disableOnInteraction: false,
					pauseOnMouseEnter: true,
				},
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			},
			observedImages: {},
		};
	},
	methods: {
		stopSwipe(event) {
			event?.target?.swiper?.autoplay?.stop();
		},
		startSwipe(event) {
			event?.target?.swiper?.autoplay?.start();
		},
		onVisible(item) {
			this.$set(this.observedImages, item.top_cover_image_url_default, true);
		},
		bgStyle(item) {
			if (this.observedImages[item.top_cover_image_url_default]) {
				return `background-image: url('${item.top_cover_image_url_default}')`;
			}
			return '';
		},
	},
};
</script>

<style lang="scss" scoped>
@import "assets/styles/sk_variables";
// For use bootstrap vars and mixins inside
@import "assets/styles/bootstrap/scss/functions";
@import "assets/styles/bootstrap/scss/variables";
@import "assets/styles/bootstrap/scss/mixins";
.header-margin {
	margin-top: 0.75rem;
	margin-bottom: .5rem;
	@include media-breakpoint-up(lg) {
//		margin-top: 9rem;

		margin-top: 1.25rem;
		// margin-bottom: .5rem;
	}
}
.panel-slider {
	height: 24.5rem;
	overflow:hidden;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	@include media-breakpoint-up(lg) {
		height: 100%;
	}
	.controls {
		bottom: 2rem;
		left: 50%;
		transform: translateX(-50%);
		height: 2.25rem;
		position: absolute;
		display: flex;
		z-index:5;
		align-items: center;
		@include media-breakpoint-up(sm) {
			bottom: 3rem;
			right: 2.25rem;
			left: unset;
			transform: none;
		}
		[class*="swiper-button"] {
			position: relative;
			top: unset;
			left: unset;
			right: unset;
			display:flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			margin:0;
			width: 2.25rem;
			height: 2.25rem;
			background: $blue;
			&::after {
				color: white;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				content: "\e908";
				font-family: "sk_icons";
				line-height: 1;
				font-size: 1rem;
			}
		}
		.swiper-button-next {
			&::after {
				content: "\e909";
			}
		}
		//.swiper-button-disabled {
		//	display:none;
		//}
	}
	.swiper-slide {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
		&::before {
			content: '';
			position: absolute;
			background: rgba(7, 15, 36, 0.5);
			top:0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index:1;
		}
	}
	.advice-item {
		position: absolute;
		left: 1.66rem;
		bottom: 4.8rem;
		right: 1.66rem;
		z-index:2;
		max-width: inherit;
		@include media-breakpoint-up(sm) {
			max-width: 60%;
			bottom: 5.8rem;
			right: unset;
		}
		.date {
			color: $theme-light;
			font-size: 1rem;
		}
		.title {
			color: $theme-light;
			font-size: 1.3rem;
			line-height: 1.1;
			@include media-breakpoint-up(sm) {
				font-size: 1.9rem;
			}
		}
	}
}
.slider-side {
	display: flex;

	@include media-breakpoint-up(lg) {
		flex-direction: column;
	}
	&__item {
		background-repeat: no-repeat;
		background-size:cover;
		padding: 2rem 1.5rem;

		.title {
			color: white;
		}
		.btn {
			margin-top: 1rem;
			margin-left:0;
			margin-right: auto;
		}
		.item-body {
			position: relative;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			@include media-breakpoint-up(lg) {
				display: block;
			}
		}
	}
	& > [class*="col-"] {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
	}
}
</style>
<style lang="scss">
.panel-slider {
	.controls {
		.swiper-pagination {
			position: relative;
			display: flex;
			align-items: center;
			margin:0 0.75rem;
			overflow: inherit;
			&-bullet {
				position:relative;
				width: 0.87rem;
				height: 0.87rem;
				margin: 0 0.1rem;
				background:transparent;
				overflow: inherit;
				opacity:1;
				&:only-child {
					display: none;
				}
				&::before {
					border-radius:50%;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					content: '';
					position: absolute;
					background: white;
					width: 0.4rem;
					height: 0.4rem;

					opacity: 1;
					transition: all 0.2s linear;
					transform-origin: center;
				}
				&-active {
					width: 0.87rem;
					height: 0.87rem;
					&::before {
						width: 0.87rem;
						height: 0.87rem;
					}

				}
			}
		}
	}
}
</style>
